<template>
  <div class="auth-wrapper">
    <b-row class="auth-inner">
      <b-col sm="12" md="6" lg="3">
        <b-img src="@/assets/images/logo.svg" />

        <h4 class="mt-5">Forgot your password?</h4>

        <b-form class="auth-login-form" @submit.prevent="login">
          <b-form-group>
            <label for="login-email">
              Email <span class="required">*</span>
            </label>
            <b-form-input
              id="login-email"
              v-model="userEmail"
              name="login-email"
            />
          </b-form-group>

          <div class="login-actions">
            <b-button type="submit" variant="success" block>
              Send instructions by email
            </b-button>
            <router-link class="forgot-password" to="/login">Log In</router-link>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BForm, BButton, BImg } from 'bootstrap-vue'
// import Toast from '@/components/Toast.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BImg,
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      userEmail: '',
    }
  },
  methods: {
    sendEmail() {

    },
  },
}
</script>
